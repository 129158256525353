import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { CloudComponent } from './body/pages/services/cloud/cloud.component';
import { DevelopmentComponent } from './body/pages/services/development/development.component';
import { DevopsComponent } from './body/pages/services/devops/devops.component';
import { TrainingsComponent } from './body/pages/services/trainings/trainings.component';

const routes: Routes = [
  { path: '', component: BodyComponent },
  { path: 'servicios/capacitaciones', component: TrainingsComponent },
  { path: 'servicios/devops', component: DevopsComponent },
  { path: 'servicios/cloud', component: CloudComponent },
  { path: 'servicios/development', component: DevelopmentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }