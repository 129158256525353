<!-- Services Section Start -->
<div id="rs-services" class="rs-services main-home style1 pt-100 md-pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/4.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a routerLink="/servicios/capacitaciones">Capacitaciones en Cloud y DevOps</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Nuestro trabajo busca generar un conocimiento en las últimas tendencias tecnológicas y metodologías de trabajo en los equipos de nuestros clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/1.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a routerLink="/servicios/devops">Implementación metodologías DevOps</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Nuestro trabajo busca generar una mayor velocidad en la entrega de valor dándole a los equipos herramientas para automatizar sus despliegues de forma controlada, con calidad y seguridad.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/2.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a routerLink="/servicios/cloud">Diseño, mantenimiento y mejoramiento de cloud</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Nuestro trabajo busca acompañar en la adopción del mundo cloud a nuestros clientes con buenas prácticas que permitan optimización de costos y recursos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/5.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a routerLink="/servicios/development">Desarrollo software en diferentes lenguajes de programación</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Nuestro trabajo busca acompañar a nuestros clientes en el desarrollo de software con velocidad y calidad para aumentar la entrega de valor a sus usuarios.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Section End -->
