<!--Full width header Start-->
<div class="full-width-header">
    <!--Header Start-->
    <header id="rs-header" class="rs-header style2 fixed-top-header">
        <!-- Topbar Area Start -->
        <div class="topbar-area style2">
            <div class="container">
                <div class="row y-middle">
                    <div class="col-lg-8">
                        <ul class="topbar-contact">
                            <li>
                                <i class="flaticon-email"></i>
                                <a href="mailto:info@stelliontech.com">info@stelliontech.com</a>
                            </li>
                            <li>
                                <i class="flaticon-call"></i>
                                <a href="https://wa.me/+573233427142">+57 (323) 3427142</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 text-right">
                        <div class="toolbar-sl-share">
                            <ul>
                                <li><a href="https://www.linkedin.com/company/stellion-tech/" target="_blanc"><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-white">
            <div class="container menu-area">
                <!-- <div class="container"> -->
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand logo-part" (click)="fragment='rs-home'" [ngClass]="{'router-link-active': fragment==='rs-home'}" href="#"><img src="assets/images/logo-dark.png" alt=""></a>
                    <button class="navbar-toggler"  (click)="showMenu = !showMenu" type="button"  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="dark-blue-text"><i class="fa fa-bars"></i></span>
                    </button>

                    <div [ngClass]="{'show': showMenu }" class="navbar-collapse show-more" id="navbarSupportedContent">

                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item" >
                                <a class="nav-link"  [routerLink]="['/']" (click)="fragment='rs-banner'; showMenu=false" fragment="rs-banner" [ngClass]="{'router-link-active': fragment==='rs-banner'}">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  [routerLink]="['/']" (click)="fragment='rs-services'; showMenu=false" fragment="rs-services" [ngClass]="{'router-link-active': fragment==='rs-services'}">Servicios</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  [routerLink]="['/']" (click)="fragment='rs-technologies'; showMenu=false" fragment="rs-technologies" [ngClass]="{'router-link-active': fragment==='rs-technologies'}">Tecnologías</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  [routerLink]="['/']" (click)="fragment='rs-about'; showMenu=false" fragment="rs-about" [ngClass]="{'router-link-active': fragment==='rs-about'}" >Acerca de nosotros</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  [routerLink]="['/']" (click)="fragment='rs-contact'; showMenu=false" fragment="rs-contact" [ngClass]="{'router-link-active': fragment==='rs-contact'}" >Contáctanos</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <!-- </div> -->
            </div>
        </div>

    </header>
    <!--Header End-->
</div>
<!--Full width header End-->
