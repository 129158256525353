import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { ServicesComponent } from './body/services/services.component';
import { TechnologiesComponent } from './body/technologies/technologies.component';
import { AboutComponent } from './body/about/about.component';
import { ContactComponent } from './body/contact/contact.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './body/banner/banner.component';
import { DevopsComponent } from './body/pages/services/devops/devops.component';
import { CloudComponent } from './body/pages/services/cloud/cloud.component';
import { DevelopmentComponent } from './body/pages/services/development/development.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    ServicesComponent,
    TechnologiesComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    BannerComponent,
    DevopsComponent,
    CloudComponent,
    DevelopmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
