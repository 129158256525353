import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  fragment: string = "asas";

  showMenu: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
