<!-- Breadcrumbs Start -->
<div class="rs-breadcrumbs img2 mt-100">
    <div class="breadcrumbs-inner text-center">
        <h1 class="page-title">Implementación metodologías DevOps</h1>
    </div>
</div>
<!-- Breadcrumbs End -->

<!-- About Section Start -->
<div class="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
    <div class="container pb-80">
        <div class="row align-items-center">
            <div class="col-lg-6 pr-40 md-pr-15 md-mb-50">
                <div class="sec-title4">
                    <span class="sub-text">Implementación</span>
                    <h2 class="title">Implementación metodologías DevOps</h2>
                    <div class="heading-line"></div>
                    <div class="desc desc-big">
                        Automatización de pruebas y despliegue de las aplicaciones nuevas o existentes en la empresa.
                        Dependiendo del presupuesto del cliente se pueden usar diferentes herramientas existentes en el
                        mercado. El objetivo principal es aumentar la velocidad en la entrega de valor en los equipos de
                        desarrollo de software.
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="software-img">
                    <img src="assets/images/services/devops.png" alt="images">
                </div>
            </div>
        </div>
    </div>

    <!-- Counter Section Start -->
    <div class="rs-counter style3 modify1 pt-90 pb-80">
        <div class="container">
            <div class="counter-top-area">
                <div class="row">
                    <div class="col-lg-4 col-md-6 md-mb-30">
                        <div class="counter-list">
                            <div class="counter-text">
                                <div class="count-number">
                                    <span class="rs-count">1</span>
                                    <span class="prefix">+</span>
                                </div>
                                <h3 class="title">Clientes felices</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 md-mb-30">
                        <div class="counter-list">
                            <div class="counter-text">
                                <div class="count-number">
                                    <span class="rs-count">1</span>
                                    <span class="prefix">+</span>
                                </div>
                                <h3 class="title">Projectos ejecutados</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="counter-list">
                            <div class="counter-text">
                                <div class="count-number">
                                    <span class="rs-count">1</span>
                                    <span class="prefix">+</span>
                                </div>
                                <h3 class="title">Presencia en paises</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Counter Section End -->

    <!-- Services Section Start -->
    <div class="rs-services style5 pt-120 md-pt-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 md-mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <img src="assets/images/services/pages/1.png" alt="Images">
                        </div>
                        <div class="services-content">
                            <h3 class="services-title"><a href="web-development.html">Equipo experto</a></h3>
                            <p class="services-desc">
                                Contamos con un equipo capacitado para retos tecnólogicos.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 md-mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <img src="assets/images/services/pages/2.png" alt="Images">
                        </div>
                        <div class="services-content">
                            <h3 class="services-title"><a href="web-development.html">Crecimiento de empresas</a></h3>
                            <p class="services-desc">
                                Acompañamos a empresas en el crecimiento tecnólogico.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="services-item">
                        <div class="services-icon">
                            <img src="assets/images/services/pages/3.png" alt="Images">
                        </div>
                        <div class="services-content">
                            <h3 class="services-title"><a href="web-development.html">Ideas creativas</a></h3>
                            <p class="services-desc">
                                Trabajamos con las tendencias en tecnología
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Services Section End -->
</div>
<!-- About Section End -->