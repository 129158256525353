<!-- Banner Section Start -->
<div id="rs-banner" class="rs-banner style3 pt-120 pb-120 md-pt-0 md-pb-0">
    <div class="container">
        <div class="banner-content">
            <div class="sub-title wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">It Software & Design</div>
            <h1 class="title wow fadeInRight" data-wow-delay="600ms" data-wow-duration="2000ms"> Stellion Tech</h1>
            <h2 class=" title-small wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
                Ongoing Adaption
            </h2>
            <div class="btn wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
                <a class="readon buy-now get-in" href="#">Get In Touch</a>
            </div>
        </div>
    </div>
</div>